import { IColumn, ISelectableOption } from '@fluentui/react';
import * as React from 'react';
import { ISchemaOfGroups, IKeyValue } from '../models/IMozzaikObjects';

export const ConditionalWrapper = ({ condition, wrapper, children }: { condition: boolean, wrapper: (children: React.ReactNode) => JSX.Element, children: React.ReactNode }) => {
  return condition ? wrapper(children) : React.createElement(React.Fragment, undefined, children);
}

export function CopyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T;
  return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

export function SimpleEmailValidator(email: string) {
  const expression: RegExp = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i;
  return expression.test(email.toLowerCase());
}

export function SortItemsByColumn(allColumns: IColumn[], allItems: any[], selectedColumn: string) {
  let newColumns: IColumn[] = allColumns.slice();
  let currentColumn: IColumn = newColumns.filter(current => selectedColumn === current.key)[0];

  newColumns.forEach((newCol: IColumn) => {
    if (newCol === currentColumn) {
      currentColumn.isSortedDescending = !currentColumn.isSortedDescending;
      currentColumn.isSorted = true;
    } else {
      newCol.isSorted = false;
      newCol.isSortedDescending = true;
    }
  });

  const newItems = CopyAndSort(allItems, currentColumn.fieldName!, currentColumn.isSortedDescending);

  return ({
    columns: newColumns,
    items: newItems
  });
}

export function arraySwap(array: any, index1: number, index2: number) {
  const swappedArray = [...array];
  const tmp = { ...swappedArray[index1] };

  swappedArray[index1] = { ...swappedArray[index2] };
  swappedArray[index2] = tmp;
  return (swappedArray)
}

export function arraySetPosition(array: any) {
  return array.map((item: any, index: number) => { item.position = index + 1; return item; })
}

export function move(arr: any, old_index: number, new_index: number) {
  while (old_index < 0) {
    old_index += arr.length;
  }
  while (new_index < 0) {
    new_index += arr.length;
  }
  if (new_index >= arr.length) {
    var k = new_index - arr.length;
    while ((k--) + 1) {
      arr.push();
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
}

export function groupByJsonObj(allSites: any, proprieteOfGroupby: string): ISchemaOfGroups[] {
  let schema = allSites.reduce(function (acc: any, obj: any) {
    var cle = obj[proprieteOfGroupby];
    if (!acc[cle]) {
      acc[cle] = [];
    }
    acc[cle].push(obj);
    return acc;
  }, {});
  const values = Object.keys(schema).map(key => schema[key]);
  let grouped: ISchemaOfGroups[] = [];
  values.forEach(val => {
    grouped.push({
      sites: val
    });
  });
  return grouped;
}

export function audiencesToJSON(audiences: IKeyValue[]): string {
  let json: string = '';

  if (audiences.length > 0) {
    let audienceJSON: any = {};
    audiences.forEach(audience => audienceJSON[audience.key] = audience.value);
    json = JSON.stringify(audienceJSON);
  }

  return json;
}

export function JSONToAudiences(json: string): IKeyValue[] {
  let keyValues: IKeyValue[] = [];

  if (json) {
    try {
      let audienceJSON: any = JSON.parse(json);
      let audiences = Object.entries<string>(audienceJSON);

      for (const [key, value] of audiences) {
        let newkey = key;

        if (key.toLowerCase() === 'language') {
          newkey = 'SPS-MUILanguages';
        } else if (key.toLowerCase() === 'department') {
          newkey = 'Department';
        } else if (key.toLowerCase() === 'jobtitle') {
          newkey = 'SPS-JobTitle';
        } else if (key.toLowerCase() === 'location') {
          newkey = 'SPS-Location';
        }

        keyValues.push({ key: newkey, value: value });
      }
    } catch (exception) {
      console.error(exception)
    }
  }

  return keyValues;
}

export function GetSelectableOption(list: string[]): ISelectableOption[] {
  let options: ISelectableOption[] = list.map(element => {
    const option: ISelectableOption = {
      key: element.toLowerCase(), text: element
    };

    return option;
  });

  options.unshift({ key: '', text: '' });

  return options;
}

export function GetUrl(url: string, last: boolean) {
  let path = url || '';

  if (url) {
    if (last) {
      if (url.startsWith('http')) {
        path = url.substring(url.lastIndexOf('/') + 1, url.length);
      }
    } else {
      if (url.startsWith('http')) {
        path = url.substring(0, url.lastIndexOf('/'));
      }
    }
  }

  return path;
}