import Config from "../../utils/Config";
import { IMember } from "./types";
import { IPublicClientApplication } from "@azure/msal-browser";

export class Mozzaik365ContributionCenterService {
  public async getMembersByResource(
    instance: IPublicClientApplication,
    clientId: string,
    resourceId: number,
  ): Promise<IMember[]> {
    const authenticationResult = await instance.acquireTokenSilent({
      scopes: [`${Config.Apps.ContributionCenterDeploymentAppId}/access_as_user`],
    });

    let response = await fetch(`${Config.URL.ContributionCenterAPI}/${clientId}/resources/${resourceId}/members`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticationResult.accessToken}`,
      },
    });

    if (response.ok) {
      return await response.json();
    }

    return [];
  }

  public async linkMemberAndResource(
    instance: IPublicClientApplication,
    clientId: string,
    resourceId: number,
    memberId: string,
  ): Promise<void> {
    const authenticationResult = await instance.acquireTokenSilent({
      scopes: [`${Config.Apps.ContributionCenterDeploymentAppId}/access_as_user`],
    });

    let response = await fetch(`${Config.URL.ContributionCenterAPI}/${clientId}/resources/${resourceId}/members`, {
      method: "PATCH",
      body: JSON.stringify([memberId]),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticationResult.accessToken}`,
      },
    });

    if (response.ok) {
      await response.json();
    }
  }

  public async unlinkMemberAndResource(
    instance: IPublicClientApplication,
    clientId: string,
    resourceId: number,
    memberId: string,
  ): Promise<void> {
    const authenticationResult = await instance.acquireTokenSilent({
      scopes: [`${Config.Apps.ContributionCenterDeploymentAppId}/access_as_user`],
    });

    let response = await fetch(
      `${Config.URL.ContributionCenterAPI}/${clientId}/resources/${resourceId}/members?memberId=${memberId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticationResult.accessToken}`,
        },
      },
    );

    if (response.ok) {
      await response.json();
    }
  }
}
