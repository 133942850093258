import {
  InteractionRequiredAuthError,
  InteractionType,
  IPublicClientApplication,
  RedirectRequest,
} from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider, useMsalAuthentication } from "@azure/msal-react";
import React, { useEffect, useMemo, useState } from "react";
import { AuthenticationWrapper } from "./components/authentication/AuthenticationWrapper";
import { defaultMozzaikAuthContext, MozzaikContext } from "./utils/helpers/MozzaikAuthContext";

type AppProps = {
  pca: IPublicClientApplication;
};

export function App({ pca }: AppProps) {
  const [mozzaikContext, setMozzaikContext] = useState(defaultMozzaikAuthContext);
  const value = { mozzaikContext, setMozzaikContext };

  const authRequest: RedirectRequest = useMemo(() => {
    return { redirectUri: window.location.origin, scopes: ["User.Read"] };
  }, []);

  const { login, result, error } = useMsalAuthentication(InteractionType.Silent, authRequest);

  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      console.log("Silent token acquisition failed. Acquiring token using redirect", error);
      login(InteractionType.Popup, authRequest);
    }
  }, [error, login, result, authRequest]);

  return (
    <MsalProvider instance={pca}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest}>
        <MozzaikContext.Provider value={value}>
          <AuthenticationWrapper />
        </MozzaikContext.Provider>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}
