import { getTheme } from '@fluentui/react';
import { StyleSheet } from 'aphrodite';

const styles = () => {
    const theme = getTheme();
    return StyleSheet.create({
        title: {
            color: theme.palette.themePrimary,
            fontWeight: 'bolder',
            fontSize: '48px',
            marginTop: 0,
            marginBottom: '75px'
        },
        content: {
            color: theme.palette.themePrimary,
            maxWidth: '750px',
            padding: '40px',
            fontSize: '17px',
            height: '80%'
        },
        container: {
            display: 'flex',            
            alignItems: 'center',
            width: '100%',
            height: '100%',
            backgroundImage: `url('/welcome.jpg')`,
            backgroundSize: 'cover'
        }
    });
}

export default styles;