import React from "react";
import styles from "./ContentHeader.module.scss";

export interface IContentHeaderProps {
  title: string;
}

const ContentHeader = (props: IContentHeaderProps) => {
  return (
    <div className={styles.header}>
      <div className={styles.title}>{props.title}</div>
    </div>
  );
};

export default React.memo(ContentHeader);
