import { getTheme } from '@fluentui/react';
import { StyleSheet } from 'aphrodite';

const styles = () => {
    const theme = getTheme();
    return StyleSheet.create({
        title: {
            color: theme.palette.themePrimary,
            fontWeight: 'bold',
            marginTop: 0
        },
        container: {
            backgroundColor: theme.palette.neutralLight,
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            width: '100%',
            height: '100%'
        },
        message: {
            width: '100%',
            padding: '35px',
            opacity: 0.9
        }
    });
}

export default styles;