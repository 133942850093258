import React, { useCallback, useContext } from "react";

import { PeoplePicker, IMember } from "@mozzaik365/mozzaik-ui";
import { MozzaikContext } from "../../utils/helpers/MozzaikAuthContext";
import { useMsal } from "@azure/msal-react";
import intl from "react-intl-universal";

const useGroupSearch = () => {
  const { instance } = useMsal();
  const { mozzaikContext } = useContext(MozzaikContext);

  const filterDataPromise = useCallback(
    async (filterText: string = ""): Promise<IMember[]> => {
      if (!filterText) {
        return [];
      }

      const data = await mozzaikContext.graphService?.getGraphGroups(instance, filterText);

      if (!data?.value || !data.value.length) {
        return [];
      }

      return data.value.map((item) => ({
        id: item.id,
        displayName: item.displayName,
        imageUrl: "",
      }));
    },
    [instance, mozzaikContext.graphService],
  );

  return { filterDataPromise };
};

interface IGroupPickerProps {
  values: IMember[];
  onGroupSelected: (value: IMember) => void;
  onGroupRemoved: (value: IMember) => void;
  onSelectedItemsChange: (values: IMember[]) => void;
}

export function GroupPicker({ values, onGroupSelected, onSelectedItemsChange, onGroupRemoved }: IGroupPickerProps) {
  const { filterDataPromise } = useGroupSearch();

  return (
    <PeoplePicker
      itemLimit={10}
      suggestionsContainerAriaLabel=""
      onSelectedItemsChange={onSelectedItemsChange}
      defaultSelectedMembers={values}
      loadingText={intl.get("SearchGroupsLoading")}
      noResultsFoundText={intl.get("SearchGroupsNotFound")}
      onItemAdded={onGroupSelected}
      onItemRemoved={onGroupRemoved}
      placeholder={intl.get("SelectGroups")}
      personaFilteredOnPromise={filterDataPromise}
    />
  );
}
