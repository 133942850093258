import { Configuration, PublicClientApplication } from "@azure/msal-browser";

export const createMsalInstance = (
  clientId: string,
  cacheLocation: "localStorage" | "sessionStorage"
): PublicClientApplication => {
  const config: Configuration = {
    auth: {
      clientId,
      redirectUri: window.location.href,
    },
    cache: {
      cacheLocation,
    },
  };
  const msalInstance = new PublicClientApplication(config);

  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  return msalInstance;
};
