import { IStackStyles, IStackTokens } from "@fluentui/react";
import {
  HOME_BUTTON_WIDTH,
  USER_INFO_CONTAINER_CHILDREN_GAP,
  USER_INFO_CONTAINER_PADDING_LEFT,
  USER_INFO_ITEM_CHILDREN_GAP,
} from "./Header.constants";

export interface IHeaderStyles {
  homeButton: IStackStyles;
  userInfoContainer: IStackStyles;
  tenantAndEmailContainer: IStackStyles;
}

export interface IHeaderTokens {
  userInfoContainer: IStackTokens;
  userInfoItem: IStackTokens;
}

export const headerTokens: IHeaderTokens = {
  userInfoContainer: {
    childrenGap: USER_INFO_CONTAINER_CHILDREN_GAP,
  },
  userInfoItem: {
    childrenGap: USER_INFO_ITEM_CHILDREN_GAP,
  },
};

export const headerStyles: IHeaderStyles = {
  homeButton: {
    root: {
      width: HOME_BUTTON_WIDTH,
    },
  },
  userInfoContainer: {
    root: {
      width: "100%",
    },
  },
  tenantAndEmailContainer: {
    root: {
      paddingLeft: USER_INFO_CONTAINER_PADDING_LEFT,
    },
  },
};
