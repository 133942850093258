import React, { useContext } from "react";
import { FunctionComponent } from "react";
import { MozzaikContext } from "../../utils/helpers/MozzaikAuthContext";

interface IUnauthentifiedMozzaikTemplateProps
{

}

export const UnauthentifiedMozzaikTemplate: FunctionComponent<IUnauthentifiedMozzaikTemplateProps> = (props) => {
    const { mozzaikContext } = useContext(MozzaikContext);

    return (
        <>
            {
                !mozzaikContext.User?.isAuthorized && props.children
            }
        </>
    );
}