import React from "react";
import { IMozzaikSubscription, IMozzaikToken } from "../../models/IMozzaikObjects";
import Mozzaik365APIService from "../../services/Mozzaik365APIService";
import Mozzaik365ClientSettingsService from "../../services/Mozzaik365ClientSettingsService";
import Mozzaik365GraphService from "../../services/Mozzaik365GraphService";
import SiteTranslatorService from "../../services/PageTranslator/PageTranslatorService";
import Config from "../Config";
import { Mozzaik365ContributionCenterService } from "../../services/ContributioCenter";

export interface ServicesDiscovery {
  TranslatorSettingsAPIURL: string;
  ClientSettingsAPIURL: string;
  ContributionCenterAPIURL: string;
  AuthenticationAPIURL: string;
}

export interface IUser {
  tenantId: string;
  tenantName: string;
  userMail: string;
  userName: string;
  isAuthorized: boolean;
  licenses: IMozzaikSubscription[] | undefined;
}

export interface IMozzBoardContext {
  userInformations: IMozzBoardUserInformations;
  hasTranslationFeature: boolean;
}
export interface IMozzBoardUserInformations {
  clients: IMozzBoardClient[];
}

export interface IMozzBoardClient {
  contracts: { [key: string]: IMozzBoardContract };
  clientId: string;
  name: string;
}

export interface IMozzBoardContract {
  featureCodes: string[];
}

export interface MozzaikAuthContext {
  AuthenticationSucceeded: boolean;
  User?: IUser;
  MozzaikToken: string;
  MozzaikDecodedToken?: IMozzaikToken;
  APIs: ServicesDiscovery;
  MozzaikService?: Mozzaik365APIService;
  ClientSettingsService?: Mozzaik365ClientSettingsService;
  ContributionCenterService?: Mozzaik365ContributionCenterService;
  graphService?: Mozzaik365GraphService;
  TranslationService?: SiteTranslatorService;
}

export const defaultMozzaikAuthContext: MozzaikAuthContext = {
  AuthenticationSucceeded: false,
  MozzaikToken: "",
  APIs: {
    TranslatorSettingsAPIURL: process.env.REACT_APP_API_TRANSLATOR_URL || Config.URL.TranslatorSettingsAPI,
    ClientSettingsAPIURL: process.env.REACT_APP_CONFIGURATOR_CLIENT_SETTINGS_API || Config.URL.ClientSettingsAPI,
    ContributionCenterAPIURL: process.env.REACT_APP_API_CONTRIBUTION_CENTER_URL!,
    AuthenticationAPIURL: process.env.REACT_APP_CONFIGURATOR_AUTH_API || Config.URL.AuthenticationAPI,
  },
  TranslationService: undefined,
};

export const MZK_TOKEN_KEY: string = "MozzaikToken";

export const MozzaikContext = React.createContext({
  mozzaikContext: defaultMozzaikAuthContext,
  setMozzaikContext: (value: MozzaikAuthContext) => {},
});
