import { IPublicClientApplication } from "@azure/msal-browser";
import { ISitePermissions, ISitePermissionsDetails } from "../models/graph/ISitePermissions";
import { IModernAudience } from "../models/IMozzaikObjects";

export interface BatchedResponse<T> {
  body: T;
  headers: { "Cache-Control": string; "Content-Type": string; "OData-Version": string; "x-ms-resource-unit": string };
  id: string;
  status: number;
}

export interface IGraphGroupsResults {
  value: IModernAudience[];
}

const baseGraphUri = "https://graph.microsoft.com/v1.0/";

export default class Mozzaik365GraphService {
  private _mozzaikToken: string;
  private _defaultHeaders: Headers;

  constructor(mozzaikToken: string) {
    this._mozzaikToken = mozzaikToken;
    this._defaultHeaders = new Headers();
    this._defaultHeaders.append("Accept", "application/json");
    this._defaultHeaders.append("Content-Type", "application/json");
  }

  private async getBatch<BodyType>(
    requests: { url: string; method: string; id: string }[],
    accessToken: string,
  ): Promise<BatchedResponse<BodyType>[]> {
    const paginedRequests = [requests.splice(0, 20)];

    while (requests.length / 20 > 1) {
      paginedRequests.push(requests.splice(0, 20));
    }

    return Promise.all(
      paginedRequests.map(async (requestsPage) => {
        const batchedResult = await fetch(`${baseGraphUri}$batch`, {
          method: "POST",
          body: JSON.stringify({
            requests: requestsPage,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        return batchedResult
          .json()
          .then((result: { responses: BatchedResponse<BodyType>[] }) =>
            result.responses
              .filter((response) => response.status === 200)
              .flatMap((response) => response as BatchedResponse<BodyType>),
          );
      }),
    ).then((value) => value.flatMap((item) => item));
  }

  public async getGraphGroups(instance: IPublicClientApplication, displayName: string): Promise<IGraphGroupsResults> {
    let authenticationResult = await instance.acquireTokenSilent({
      scopes: ["GroupMember.Read.All"],
    });

    let graphGroupsResult = await fetch(
      baseGraphUri + `groups?$select=id,displayName&$search="displayName:${displayName}"&$orderby=displayName`,
      {
        method: "GET",
        headers: {
          ...this._defaultHeaders,
          Authorization: `Bearer ${authenticationResult.accessToken}`,
          ConsistencyLevel: "eventual",
        },
      },
    );
    return await graphGroupsResult.json();
  }

  public async getGroupsAndPeopleById(instance: IPublicClientApplication, ids: string[]): Promise<IGraphGroupsResults> {
    let authenticationResult = await instance.acquireTokenSilent({
      scopes: ["GroupMember.Read.All"],
    });

    let graphGroupsResult = await fetch(baseGraphUri + `directoryObjects/getByIds`, {
      method: "POST",
      body: JSON.stringify({
        ids: ids,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${authenticationResult.accessToken}`,
      },
    });
    return await graphGroupsResult.json();
  }

  public async getBatchedSitePermissionsByPermissionId(
    accessToken: string,
    siteId: string,
    permissionsId: string[],
  ): Promise<ISitePermissionsDetails[]> {
    return this.getBatch<ISitePermissionsDetails[]>(
      permissionsId.map((permissionId) => ({
        url: `sites/${siteId}/permissions/${permissionId}`,
        method: "GET",
        id: permissionId,
      })),
      accessToken,
    ).then((responses) => responses.flatMap((response) => response.body));
  }

  public async getSitesId(accessToken: string, URLs: URL[]): Promise<Map<string, string>> {
    return this.getBatch<{ id: string }>(
      URLs.map((url) => ({
        id: url.href,
        method: "GET",
        url: `sites/${url.hostname}:${url.pathname}`,
      })),
      accessToken,
    ).then((responses) => new Map<string, string>(responses.map((response) => [response.id, response.body.id])));
  }

  public async getPermissionsFromSites(
    accessToken: string,
    sitesIdList: string[],
  ): Promise<Map<string, ISitePermissions[]>> {
    return this.getBatch<{ value: ISitePermissions[] }>(
      sitesIdList.map((siteId) => ({
        id: siteId,
        method: "GET",
        url: `sites/${siteId}/permissions`,
      })),
      accessToken,
    ).then(
      (responses) =>
        new Map<string, ISitePermissions[]>(responses.map((response) => [response.id, response.body.value])),
    );
  }
}
