import React, { useContext } from "react";
import { Switch, Route } from "react-router-dom";
import { MozzaikContext } from "../../utils/helpers/MozzaikAuthContext";
import Unauthorized from "../Unauthorized";

export const UnauthentifiedRoutes = () => {
    const { mozzaikContext } = useContext(MozzaikContext);
    
    return (
        <Switch>
            <Route exact path="*">
                <Unauthorized tenant={mozzaikContext.User!.tenantName} usermail={mozzaikContext.User!.userMail} />
            </Route>
        </Switch>
    );
}