const Config = {
  // TODO : app settings
  Solutions: {
    Mozzaik365UnifiedExperience: {
      ID: "f5893213-c5c4-411a-aea5-1fb74ecf8776",
      URL: process.env.REACT_APP_UEX_PACKAGE_URL!,
    },
    Mozzaik365LegacyWebparts: {
      ID: "8243e570-6ab6-4482-aac9-e3de8387f21c",
      URL: process.env.REACT_APP_LEGACY_WEBPARTS_PACKAGE_URL!,
    },
    Mozzaik365Webparts: {
      ID: "54544D32-4E56-646C-596C-4268636E527A",
      URL: process.env.REACT_APP_WEBPARTS_PACKAGE_URL!,
    },
  },
  URL: {
    ContributionCenterAPI: process.env.REACT_APP_API_CONTRIBUTION_CENTER_URL!,
    ClientSettingsAPI: process.env.REACT_APP_API_CLIENTSETTINGS_URL!,
    AuthenticationAPI: process.env.REACT_APP_API_AUTHENTICATION_URL!,
    EngineAPI: process.env.REACT_APP_API_SITEENGINE_URL!,
    LogsAPI: process.env.REACT_APP_API_LOGS_URL!,
    TranslatorSettingsAPI: process.env.REACT_APP_API_TRANSLATOR_URL!,
    AppOnlyMozzaikPermissionProcedure: "https://mozzaik365.zendesk.com/hc/fr/articles/6105378736017",
    AppOnlyMicrosoftDoc: "https://docs.microsoft.com/en-us/sharepoint/dev/solution-guidance/security-apponly-azuread",
    UpdatePermissions: `https://login.microsoftonline.com/common/adminconsent?client_id=${process.env.REACT_APP_CLIENT_ID_CONFIGURATOR}&state=12345&redirect_uri=${process.env.REACT_APP_SITE_CONFIGURATOR_URL}`,
    ConsentToMozzaikDeploymentApp: `https://login.microsoftonline.com/common/adminconsent?client_id=${process.env.REACT_APP_MOZZAIK_DEPLOYMENT_CLIENT_ID}&state=12345&redirect_uri=${process.env.REACT_APP_SITE_CONFIGURATOR_URL}/installation`,
    MZKEngine: `https://login.microsoftonline.com/common/adminconsent?client_id=${process.env.REACT_APP_CLIENT_ID_SITEENGINE}&state=12345&redirect_uri=${process.env.REACT_APP_SITE_CONFIGURATOR_URL}`,
    MZKAdmins: `https://login.microsoftonline.com/common/adminconsent?client_id=${process.env.REACT_APP_CLIENT_ID_ADMINISTRATION}&state=12345&redirect_uri=${process.env.REACT_APP_SITE_CONFIGURATOR_URL}`,
    SupportTechnique: "https://mozzaik365.zendesk.com/hc",
  },
  Apps: {
    MozzaikDeploymentAppID: process.env.REACT_APP_MOZZAIK_DEPLOYMENT_CLIENT_ID,
    ContributionCenterDeploymentAppId: process.env.REACT_APP_CONTRIBUTION_CENTER_APP_ID_URL,
  },
};

export default Config;
