import React, { useContext, useEffect, useState, useCallback } from "react";

import intl from "react-intl-universal";
import { Text, FontType, IMember } from "@mozzaik365/mozzaik-ui";
import { GroupPicker } from "./GroupPicker";
import { MozzaikContext } from "../../utils/helpers/MozzaikAuthContext";
import { useMsal } from "@azure/msal-react";

const usePermissionMembers = (permissionId: number) => {
  const { instance } = useMsal();
  const [membersWithPermissions, setMembersWithPermissions] = useState<IMember[]>([]);

  const [loading, setLoading] = useState(false);

  const { mozzaikContext } = useContext(MozzaikContext);

  useEffect(() => {
    const fetchMembersWithPermission = async () => {
      setLoading(true);

      try {
        const members = await mozzaikContext.ContributionCenterService?.getMembersByResource(
          instance,
          mozzaikContext.User!.tenantId,

          permissionId,
        );

        const memberIds = members?.map((item) => item.externalId) || [];

        if (memberIds.length) {
          const groups = await mozzaikContext.graphService?.getGroupsAndPeopleById(instance, memberIds);

          if (groups) {
            setMembersWithPermissions(
              groups.value.map((item: IMember) => ({ id: item.id, displayName: item.displayName })),
            );
          }
        }
      } finally {
        // whatever happens we want to stop the loading
        setLoading(false);
      }
    };

    fetchMembersWithPermission();
  }, [
    instance,
    mozzaikContext.ContributionCenterService,
    mozzaikContext.User,
    mozzaikContext.graphService,
    permissionId,
  ]);

  const onGroupSelected = useCallback(
    async (member) => {
      if (member) {
        await mozzaikContext.ContributionCenterService?.linkMemberAndResource(
          instance,
          mozzaikContext.User!.tenantId,
          permissionId,
          member.id,
        );
      }
    },
    [mozzaikContext.ContributionCenterService, mozzaikContext.User, permissionId, instance],
  );

  const onGroupRemoved = useCallback(
    async (member) => {
      if (member) {
        await mozzaikContext.ContributionCenterService?.unlinkMemberAndResource(
          instance,
          mozzaikContext.User!.tenantId,
          permissionId,
          member.id!,
        );
      }
    },
    [mozzaikContext.ContributionCenterService, mozzaikContext.User, permissionId, instance],
  );

  return {
    membersWithPermissions,
    setMembersWithPermissions,
    onGroupSelected,
    onGroupRemoved,
    loading,
  };
};

const CREATE_CONTENT_PERMISSION_ID = 1;

export function Ice() {
  const { membersWithPermissions, loading, onGroupSelected, onGroupRemoved, setMembersWithPermissions } =
    usePermissionMembers(CREATE_CONTENT_PERMISSION_ID);

  return (
    <>
      <Text content={intl.get("SelectUsersAndGroupsToAccess")} type={FontType.SubjectTitle} maxLine={1} />
      {!loading && (
        <GroupPicker
          values={membersWithPermissions}
          onSelectedItemsChange={setMembersWithPermissions}
          onGroupSelected={onGroupSelected}
          onGroupRemoved={onGroupRemoved}
        />
      )}
    </>
  );
}
