import React, { useContext } from "react";
import { FunctionComponent } from "react";
import { MozzaikContext } from "../../utils/helpers/MozzaikAuthContext";

interface IAuthentifiedMozzaikTemplateProps
{

}

export const AuthentifiedMozzaikTemplate: FunctionComponent<IAuthentifiedMozzaikTemplateProps> = (props) => {
    const { mozzaikContext } = useContext(MozzaikContext);
    
    return (
        <>
            {
                mozzaikContext.User?.isAuthorized && props.children
            }
        </>
    );
}