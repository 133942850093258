import React from 'react';
import intl from 'react-intl-universal';
import { css } from 'aphrodite';
import styles from './Unauthorized.styles'


export interface IUnauthorizedProps {
    usermail: string;
    tenant: string;
}

const Unauthorized = (props: IUnauthorizedProps) => 
    <div className={css(styles().container)}>
        <div className={css(styles().message)}>
            <h2 className={css(styles().title)}>{intl.get('AccessDenied')}</h2>
            <p>{intl.get('NotAdminAccount', { email: props.usermail, tenantID: props.tenant })}</p>
        </div>
    </div>
;

export default React.memo(Unauthorized);