import React from 'react';
import intl from 'react-intl-universal';
import { css } from 'aphrodite';
import styles from './WelcomePage.styles'

const WelcomePage = () =>     
    <div className={css(styles().container)}>
        <div className={css(styles().content)}>
            <h1 className={css(styles().title)}>{intl.get('Configurator')}</h1>
            <p>
                {intl.getHTML('Welcome')}
            </p>
        </div>
    </div>
;

export default React.memo(WelcomePage);