import React, { useContext } from "react";
import intl from "react-intl-universal";
import { Route, Switch } from "react-router-dom";
import { useClientSettings } from "../../utils/helpers/ClientSettings";
import { MozzaikContext } from "../../utils/helpers/MozzaikAuthContext";
import AppNavigation from "../AppNavigation";
import ContentHeader from "../common/ContentHeader";
import Header from "../Header";
import styles from "../Home.module.scss";
import { Ice } from "../ice/Ice";
import WelcomePage from "../WelcomePage";

const UsersManagement = React.lazy(() => import("../users/UsersManagement" /* webpackChunkName: "usersmanagement" */));
const Alert = React.lazy(() => import("../uex/alert/Alert" /* webpackChunkName: "alert" */));
const Search = React.lazy(() => import("../uex/search/Search" /* webpackChunkName: "search" */));
const Theme = React.lazy(() => import("../uex/theme/Theme" /* webpackChunkName: "theme" */));
const Footer = React.lazy(() => import("../uex/footer/Footer" /* webpackChunkName: "footer" */));
const Yammer = React.lazy(() => import("../uex/yammer/Yammer" /* webpackChunkName: "yammer" */));
const Applications = React.lazy(() => import("../uex/apps/Applications" /* webpackChunkName: "apps" */));
const Navigation = React.lazy(() => import("../uex/navigation/Navigation" /* webpackChunkName: "nav" */));
const News = React.lazy(() => import("../uex/news/News" /* webpackChunkName: "news" */));
const Multilingualism = React.lazy(
  () => import("../multilingualism/Multilingualism" /* webpackChunkName: "multilingualism" */)
);
const SiteEngine = React.lazy(() => import("../siteengine/SiteEngine" /* webpackChunkName: "siteengine" */));
const PageTranslatorLandingPage = React.lazy(
  () => import("../page-translator/PageTranslatorLandingPage" /* webpackChunkName: "page-translator-landing-page" */)
);
const MetricsWrapper = React.lazy(() => import("../metrics/MetricsWrapper" /* webpackChunkName: "metrics-wrapper" */));
const Activations = React.lazy(() => import("../uex/activations/Activations" /* webpackChunkName: "activations" */));
const Installation = React.lazy(() => import("../installation/Installation" /* webpackChunkName: "installation" */));
const AppOnlyConfig = React.lazy(
  () => import("../installation/appOnly/AppOnlyConfig" /* webpackChunkName: "appOnly" */)
);

const NewhubLinkedin = React.lazy(() => import("../newshub/LinkedInCmp" /* webpackChunkName: "linkedin" */));
const NewshubRSS = React.lazy(() => import("../newshub/RSSCmp" /* webpackChunkName: "rss" */));
const NewshubYoutube = React.lazy(() => import("../newshub/YoutubeCmp" /* webpackChunkName: "youtube" */));
const NewshubFacebook = React.lazy(
  () => import("../newshub/Facebook/MetaFeedsConfiguration" /* webpackChunkName: "metaV2" */)
);

export const AuthentifiedRoutes = () => {
  const { mozzaikContext } = useContext(MozzaikContext);
  const clientSettings = useClientSettings(mozzaikContext.APIs.ClientSettingsAPIURL, mozzaikContext.MozzaikToken);

  return (
    <>
      <Header
        username={mozzaikContext.User!.userName}
        email={mozzaikContext.User!.userMail}
        tenant={mozzaikContext.User!.tenantName}
      />
      {clientSettings && (
        <div className={styles.body}>
          <AppNavigation clientSettings={clientSettings} usermail={mozzaikContext.User!.userMail} />

          <div className={styles.content}>
            <React.Suspense fallback={<div></div>}>
              <Switch>
                <Route exact path="/">
                  <WelcomePage />
                </Route>
                <>
                  {!!clientSettings.isGlobalAdmin && (
                    <>
                      <Route path="/users">
                        <div className={styles.padding}>
                          <ContentHeader title={intl.get("Users")} />
                          <UsersManagement />
                        </div>
                      </Route>
                      {(!!clientSettings.Installation || clientSettings.Installation === undefined) && (
                        <Route path="/installation">
                          <div className={styles.padding}>
                            <ContentHeader title={intl.get("Installation")} />
                            <Installation />
                          </div>
                        </Route>
                      )}
                      <Route path={"/appOnly"}>
                        <div className={styles.padding}>
                          <AppOnlyConfig installation={clientSettings.Installation} />
                        </div>
                      </Route>
                      <Route path={`/ice`}>
                        <div className={styles.padding}>
                          <ContentHeader title={intl.get("ContributionCenter")} />
                          <Ice />
                        </div>
                      </Route>
                    </>
                  )}
                  {clientSettings.NewsHUB && (
                    <>
                      <Route
                        path="/newshub"
                        render={({ match: { url } }) => (
                          <>
                            <Route path={`${url}/rss`}>
                              <div className={styles.padding}>
                                <ContentHeader title={intl.get("Newshub.FlowRSS")} />
                                <NewshubRSS />
                              </div>
                            </Route>
                            <Route path={`${url}/youtube`}>
                              <div className={styles.padding}>
                                <ContentHeader title={intl.get("Newshub.Youtube")} />
                                <NewshubYoutube />
                              </div>
                            </Route>
                            <Route path={`${url}/facebook`}>
                              <div className={styles.padding}>
                                <ContentHeader title={intl.get("Newshub.NewshubFacebook")} />
                                <NewshubFacebook />
                              </div>
                            </Route>
                            <Route path={`${url}/linkedin`}>
                              <div className={styles.padding}>
                                <ContentHeader title={intl.get("Newshub.LinkedIn")} />
                                <NewhubLinkedin />
                              </div>
                            </Route>
                          </>
                        )}
                      />
                    </>
                  )}
                  {clientSettings.UEX && (
                    <>
                      <Route
                        path="/uex"
                        render={({ match: { url } }) => (
                          <>
                            <Route path={`${url}/activations`}>
                              <div className={styles.padding}>
                                <ContentHeader title={intl.get("ActivationsTitle")} />
                                <Activations />
                              </div>
                            </Route>
                            <Route path={`${url}/alert`}>
                              <div className={styles.padding}>
                                <ContentHeader title={intl.get("Alert")} />
                                <Alert />
                              </div>
                            </Route>
                            <Route path={`${url}/search`}>
                              <div className={styles.padding}>
                                <ContentHeader title={intl.get("Search")} />
                                <Search />
                              </div>
                            </Route>
                            <Route path={`${url}/news`}>
                              <div className={styles.padding}>
                                <ContentHeader title={intl.get("News")} />
                                <News />
                              </div>
                            </Route>
                            <Route path={`${url}/navigation`}>
                              <div className={styles.padding}>
                                <ContentHeader title={intl.get("Navigation")} />
                                <Navigation />
                              </div>
                            </Route>
                            <Route path={`${url}/footer`}>
                              <div className={styles.padding}>
                                <ContentHeader title={intl.get("Footer")} />
                                <Footer />
                              </div>
                            </Route>
                            <Route path={`${url}/theme`}>
                              <div className={styles.padding}>
                                <ContentHeader title={intl.get("Theme")} />
                                <Theme />
                              </div>
                            </Route>
                            <Route path={`${url}/yammer`}>
                              <div className={styles.padding}>
                                <ContentHeader title={intl.get("Yammer")} />
                                <Yammer />
                              </div>
                            </Route>
                            <Route path={`${url}/apps`}>
                              <div className={styles.padding}>
                                <ContentHeader title={intl.get("Applications")} />
                                <Applications />
                              </div>
                            </Route>
                          </>
                        )}
                      />
                      <Route path="/multi">
                        <div className={styles.padding}>
                          <ContentHeader title={intl.get("Multilingualism")} />
                          <Multilingualism />
                        </div>
                      </Route>
                    </>
                  )}
                  {clientSettings.SiteEngine && (
                    <Route path="/siteengine">
                      <div className={styles.padding}>
                        <ContentHeader title={intl.get("SiteEngine")} />
                        <SiteEngine />
                      </div>
                    </Route>
                  )}
                  <Route path="/translator">
                    <div className={styles.padding}>
                      <ContentHeader title={intl.get("Translator")} />
                      <PageTranslatorLandingPage />
                    </div>
                  </Route>
                  <Route
                    path="/metrics"
                    render={({ match: { url } }) => (
                      <>
                        <Route path={`${url}/main`}>
                          <div className={styles.padding}>
                            <MetricsWrapper />
                          </div>
                        </Route>
                        <Route path={`${url}/commitment-and-communications`}>
                          <div className={styles.padding}>
                            <MetricsWrapper />
                          </div>
                        </Route>
                      </>
                    )}
                  />
                </>
              </Switch>
            </React.Suspense>
          </div>
        </div>
      )}
    </>
  );
};
