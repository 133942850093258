import * as React from "react";
import styles from "./AppNavigation.module.scss";
import Section from "./common/Section";
import intl from "react-intl-universal";

import { ReactComponent as user } from "../utils/svg/user.svg";
import { ReactComponent as earth } from "../utils/svg/earth.svg";
import { ReactComponent as building } from "../utils/svg/building.svg";
import { ReactComponent as group } from "../utils/svg/group.svg";
import { ReactComponent as newspaper } from "../utils/svg/newspaper.svg";
import { ReactComponent as settings } from "../utils/svg/settings.svg";
import { ReactComponent as update } from "../utils/svg/update.svg";
import { ReactComponent as metrics } from "../utils/svg/metrics.svg";
import { IClientSettings } from "../models/IMozzaikObjects";
import Config from "../utils/Config";
export interface IAppNavigationProps {
  usermail: string;
  clientSettings: IClientSettings;
}
const MozzaikOnPrem: string = "@mozzaik365.onmicrosoft.com";
const Navigation = (userMail: string) => {
  let navigation = [];
  if (userMail.indexOf(MozzaikOnPrem) === -1) {
    navigation = [
      { title: intl.get("Activations"), relativeLink: "uex/activations" },
      { title: intl.get("Alert"), relativeLink: "uex/alert" },
      { title: intl.get("Search"), relativeLink: "uex/search" },
      { title: intl.get("News"), relativeLink: "uex/news" },
      { title: intl.get("Navigation"), relativeLink: "uex/navigation" },
      { title: intl.get("Footer"), relativeLink: "uex/footer" },
      { title: intl.get("Theme"), relativeLink: "uex/theme" },
      { title: intl.get("Yammer"), relativeLink: "uex/yammer" },
      { title: intl.get("Applications"), relativeLink: "uex/apps" },
    ];
  } else {
    navigation = [
      { title: intl.get("Alert"), relativeLink: "uex/alert" },
      { title: intl.get("News"), relativeLink: "uex/news" },
      { title: intl.get("Navigation"), relativeLink: "uex/navigation" },
      { title: intl.get("Footer"), relativeLink: "uex/footer" },
      { title: intl.get("Theme"), relativeLink: "uex/theme" },
      { title: intl.get("Applications"), relativeLink: "uex/apps" },
    ];
  }
  return navigation;
};

const NavigationNewshub = () => {
  let navigation = [];
  navigation = [
    { title: intl.get("Newshub.LinkedIn"), relativeLink: "newshub/linkedin" },
    { title: intl.get("Newshub.FlowRSS"), relativeLink: "newshub/rss" },
    { title: intl.get("Newshub.Youtube"), relativeLink: "newshub/youtube" },
    {
      title: intl.get("Newshub.NewshubFacebook"),
      relativeLink: "newshub/facebook",
    },
  ];

  return navigation;
};

const NavigationMetrics = () => {
  const navigation = [
    { title: intl.get("YourDigitalWorkplace"), relativeLink: "metrics/main" },
    { title: intl.get("CommitmentAndCommunication"), relativeLink: "metrics/commitment-and-communications" },
  ];
  return navigation;
};

const AppNavigation = (props: IAppNavigationProps) => {
  const { clientSettings } = props;

  return (
    <nav className={styles.navigation}>
      {clientSettings.isGlobalAdmin && <Section title={intl.get("Users")} icon={user} relativeLink={"users"}></Section>}
      {clientSettings.isGlobalAdmin && !clientSettings.Installation?.IsAuto && (
        <Section title={intl.get("Installation")} icon={update} relativeLink={"installation"}></Section>
      )}
      {clientSettings.UEX && (
        <Section title={intl.get("UEX")} icon={group} subSections={Navigation(props.usermail)}></Section>
      )}
      {clientSettings.SiteEngine && props.usermail.indexOf(MozzaikOnPrem) === -1 && (
        <Section title={intl.get("SiteEngine")} icon={building} relativeLink={"siteengine"}></Section>
      )}
      {clientSettings.UEX && clientSettings.UEX.multiFlag && (
        <Section title={intl.get("Multilingualism")} icon={earth} relativeLink={"multi"}></Section>
      )}
      {clientSettings.isGlobalAdmin && (
        <Section title={intl.get("ContributionCenter")} icon={earth} relativeLink={"ice"}></Section>
      )}
      {clientSettings.isGlobalAdmin && (
        <Section title={intl.get("Translator")} icon={earth} relativeLink={"translator"}></Section>
      )}
      {clientSettings.isGlobalAdmin && (
        <Section title={intl.get("Metrics")} icon={metrics} subSections={NavigationMetrics()}></Section>
      )}
      {/* {
      clientSettings.NewsHUB && props.usermail.indexOf(MozzaikOnPrem) === -1 &&
      <Section title={intl.get('NewsHub')} icon={newspaper} absoluteLink={Config.URL.NewsHUBConfiguratorSite}></Section>
    } */}
      {clientSettings.NewsHUB && clientSettings.Admins && props.usermail.indexOf(MozzaikOnPrem) === -1 && (
        <Section title={intl.get("NewsHub")} icon={newspaper} subSections={NavigationNewshub()}></Section>
      )}
      <div style={{ flex: 1 }}>
        <div></div>
      </div>
      {<Section title={intl.get("Support")} icon={settings} absoluteLink={Config.URL.SupportTechnique}></Section>}
    </nav>
  );
};

export default React.memo(AppNavigation);
