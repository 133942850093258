import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { mergeStyles, createTheme, loadTheme, initializeIcons } from "@fluentui/react";
import { AuthenticationResult, EventMessage, EventType } from "@azure/msal-browser";
import { App } from "./App";
import { createMsalInstance } from "./utils/helpers/msal/InstanceCreation";

mergeStyles({
  selectors: {
    ":global(*), :global(*:before), :global(*:after)": {
      boxSizing: "border-box",
    },
    ":global(body), :global(html)": {
      width: "100%",
      height: "100%",

      margin: 0,
      padding: 0,
    },
    ":global(body)": {
      fontFamily:
        '"Segoe UI Web (West European)",Segoe UI,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif',
    },
    ":global(#root)": {
      height: "100%",
    },
    ":global(p)": {
      margin: 0,
    },
  },
});

initializeIcons();

const theme = createTheme({
  palette: {
    themePrimary: "#361d79",
    themeLighterAlt: "#f9f6fd",
    themeLighter: "#e6dcf7",
    themeLight: "#d1bff0",
    themeTertiary: "#a685e0",
    themeSecondary: "#8254d2",
    themeDarkAlt: "#693bb8",
    themeDark: "#58329b",
    themeDarker: "#412572",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#ededed",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#595959",
    neutralSecondary: "#373737",
    neutralPrimaryAlt: "#2f2f2f",
    neutralPrimary: "#000000",
    neutralDark: "#151515",
    black: "#0b0b0b",
    white: "#ffffff",
  },
  semanticColors: {
    bodyBackground: "#ffffff",
    bodyText: "#000000",
    errorText: "#a4262c",
    warningText: "#333333",
    successText: "#107C10",
    errorBackground: "rgba(232, 17, 35, .2)",
    warningBackground: "rgba(255, 185, 0, .2)",
    successBackground: "rgba(186, 216, 10, .2)",
  },
});

loadTheme(theme);

const msalInstance = createMsalInstance(process.env.REACT_APP_CLIENT_ID_CONFIGURATOR!, "localStorage");

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

ReactDOM.render(<App pca={msalInstance} />, document.getElementById("root"));
