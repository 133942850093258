import * as React from 'react';
import styles from './Section.module.scss';
import { ConditionalWrapper } from '../../utils/CommonLibrary';
import { Link } from 'react-router-dom';
import { FontIcon } from '@fluentui/react';
import { useState } from 'react';

export interface ISubSection {
    title: string;
    relativeLink: string;
}

export interface ISectionProps {
    title: string;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    relativeLink?: string;
    absoluteLink?: string;
    defaultExpand?: boolean;
    subSections?: ISubSection[];
}

export const Section: React.FunctionComponent<ISectionProps> = (props) => {
    const [isExpanded, setIsExapanded] = useState<boolean>(props.defaultExpand || false);

    return (
        <div className={styles.section}>
            <ConditionalWrapper
                condition={props.subSections !== undefined && props.subSections.length > 0}
                wrapper={(children: React.ReactNode) => <div onClick={() => setIsExapanded(!isExpanded)}>{children}</div>}>

                <ConditionalWrapper
                    condition={!!props.relativeLink}
                    wrapper={(children: React.ReactNode) => <Link className={styles.link} to={`/${props.relativeLink}`}>{children}</Link>}>

                    <ConditionalWrapper
                        condition={!!props.absoluteLink}
                        wrapper={(children: React.ReactNode) =>
                        <a className={styles.link} href={props.absoluteLink} target={'_blank'} rel="noreferrer">{children}</a>}>
                        <>
                            <div className={styles.main}>
                                <div className={styles.icon}>
                                    <props.icon className={styles.svg}></props.icon>
                                </div>

                                <div className={styles.title}>{props.title}</div>
                                {
                                    props.subSections && props.subSections.length > 0 &&
                                    <FontIcon iconName={isExpanded ? "ChevronUp" : "ChevronDown"} className={styles.icon} />
                                }
                            </div>
                        </>
                    </ConditionalWrapper>
                </ConditionalWrapper>
            </ConditionalWrapper>
            {
                isExpanded && props.subSections && props.subSections.length > 0 &&
                <div className={styles.subsections}>
                    {
                        props.subSections.map(subsection =>
                            <Link key={subsection.relativeLink} className={styles.link} to={`/${subsection.relativeLink}`}>
                                <div className={styles.subsection}>{subsection.title}</div>
                            </Link>
                        )
                    }
                </div>

            }
        </div>
    );
}

export default Section;