import { useState, useEffect } from "react";
import { IClientSettings } from "../../models/IMozzaikObjects";
import * as PageTranslatorConstants from "../../services/PageTranslator/PageTranslatorService.constants";
import {
  IMozzBoardClient,
  IMozzBoardContext,
  IMozzBoardContract,
  IMozzBoardUserInformations,
} from "./MozzaikAuthContext";

export class ClientSettings {
  public static async load(clientSettingsApiUrl: string, mozzaikToken: string): Promise<IClientSettings | undefined> {
    let headers = new Headers();
    headers.append("Authorization", `JWT ${mozzaikToken}`);

    let response = await fetch(clientSettingsApiUrl + "/api/tenant/admin", {
      method: "GET",
      headers: headers,
    });
    if (!response.ok) return undefined;

    return response.json();
  }

  public static async loadMozzBoardContext(
    mozzboardMeURL: string,
    mozzaikToken: string
  ): Promise<IMozzBoardContext | undefined> {
    let headers = new Headers();
    headers.append("Authorization", `Bearer ${mozzaikToken}`);

    let response = await fetch(mozzboardMeURL + "/me", {
      method: "GET",
      headers: headers,
    });

    if (!response.ok) return undefined;

    const userInformations: IMozzBoardUserInformations = await response.json();

    const hasTranslationFeature = userInformations.clients.some((client: IMozzBoardClient) => {
      const contractKeys = Object.keys(client.contracts);
      return contractKeys.some((contractKey: string) => {
        const contract: IMozzBoardContract = client.contracts[contractKey];

        return (
          contractKey === PageTranslatorConstants.PRODUCT_NAME &&
          contract.featureCodes.some((feature: string) => feature === PageTranslatorConstants.FEATURE_NAME)
        );
      });
    });

    const mozzBoardContext: IMozzBoardContext = {
      userInformations: userInformations,
      hasTranslationFeature: hasTranslationFeature,
    };

    return mozzBoardContext;
  }
}

export function useClientSettings(clientSettingsApiUrl: string, mozzaikToken: string) {
  const [clientSettings, setClientSettings] = useState<IClientSettings>();

  async function load(clientSettingsApiUrl: string, mozzaikToken: string): Promise<IClientSettings | undefined> {
    let headers = new Headers();
    headers.append("Authorization", `JWT ${mozzaikToken}`);

    let response = await fetch(clientSettingsApiUrl + "/api/tenant/admin", {
      method: "GET",
      headers: headers,
    });

    if (!response.ok) return undefined;

    return response.json();
  }

  useEffect(() => {
    load(clientSettingsApiUrl, mozzaikToken).then((value) => setClientSettings(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return clientSettings;
}
