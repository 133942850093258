import * as React from "react";
import styles from "./Header.module.scss";
import { headerStyles, headerTokens } from "./Header.styles";
import intl from "react-intl-universal";
import { Link } from "react-router-dom";
import { IconButton, DefaultButton, Stack, Icon, Text, VerticalDivider } from "@fluentui/react";
import * as CacheManager from "../utils/helpers/Cache";

interface IHeaderProps {
  username: string;
  tenant: string;
  email: string;
}

const Header = (props: IHeaderProps) => {
  const handleDisconnect = () => {
    CacheManager.deleteCache();
    window.open(
      "https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=" + window.location.origin,
      "_self"
    );
  };

  return (
    <header className={styles.header}>
      <Stack horizontal verticalAlign="center" styles={headerStyles.homeButton}>
        <IconButton
          iconProps={{ iconName: "Waffle" }}
          className={styles.icon}
          onClick={() => {
            window.open("https://www.office.com/?auth=2&home=1", "_blank");
          }}
        />
        <Link className={styles.title} key={"home"} to={`/`}>
          <div>{intl.get("Configurator")}</div>
        </Link>
      </Stack>

      <Stack horizontal horizontalAlign="end" verticalAlign="center" styles={headerStyles.userInfoContainer}>
        <Stack horizontal tokens={headerTokens.userInfoContainer} styles={headerStyles.tenantAndEmailContainer}>
          <Stack horizontal tokens={headerTokens.userInfoItem}>
            <Text>{props.tenant}</Text>
          </Stack>
          <VerticalDivider styles={{ wrapper: { height: "auto" } }} />
          <Stack horizontal tokens={headerTokens.userInfoItem}>
            <Icon iconName="Contact" />
            <Text>{props.email}</Text>
          </Stack>
        </Stack>
        <DefaultButton
          text={props.username}
          className={styles.user}
          split
          splitButtonAriaLabel="Disconnect"
          aria-roledescription="disconnet button"
          menuProps={{
            items: [
              {
                key: "disconnectButton",
                text: intl.get("Disconnect"),
                iconProps: { iconName: "SignOut" },
                onClick: handleDisconnect,
              },
            ],
          }}
        />
      </Stack>
    </header>
  );
};

export default React.memo(Header);
