const CONSTANTS = {
    CACHE_DELIMETER: '||'
};
/**
 * Saves the key-value pair in the cache
 * @ignore
 */
export function saveItem(cacheLocation: string, key: string, obj: any, preserve?: boolean) {
    if (cacheLocation === 'localStorage') {
        if (!supportsLocalStorage()) {
            console.info('Local storage is not supported');
            return false;
        }

        if (preserve) {
            var value = getItem(cacheLocation, key) || '';
            localStorage.setItem(key, value + obj + CONSTANTS.CACHE_DELIMETER);
        } else {
            localStorage.setItem(key, obj);
        }

        return true;
    }

    // Default as session storage
    if (!supportsSessionStorage()) {
        console.info('Session storage is not supported');
        return false;
    }

    sessionStorage.setItem(key, obj);
    return true;
}

/**
 * Searches the value for the given key in the cache
 * @ignore
 */
export function getItem(cacheLocation: string, key: string) {
    if (cacheLocation === 'localStorage') {
        if (!supportsLocalStorage()) {
            console.info('Local storage is not supported');
            return null;
        }

        return localStorage.getItem(key);
    }

    // Default as session storage
    if (!supportsSessionStorage()) {
        console.info('Session storage is not supported');
        return null;
    }

    return sessionStorage.getItem(key);
}


export function deleteCache() {
    localStorage.clear();
}


/**
 * Returns true if browser supports localStorage, false otherwise.
 * @ignore
 */
function supportsLocalStorage() {
    try {
        if (!window.localStorage) return false; // Test availability
        window.localStorage.setItem('storageTest', 'A'); // Try write
        if (window.localStorage.getItem('storageTest') !== 'A') return false; // Test read/write
        window.localStorage.removeItem('storageTest'); // Try delete
        if (window.localStorage.getItem('storageTest')) return false; // Test delete
        return true; // Success
    } catch (e) {
        return false;
    }
}

/**
 * Returns true if browser supports sessionStorage, false otherwise.
 * @ignore
 */
function supportsSessionStorage() {
    try {
        if (!window.sessionStorage) return false; // Test availability
        window.sessionStorage.setItem('storageTest', 'A'); // Try write
        if (window.sessionStorage.getItem('storageTest') !== 'A') return false; // Test read/write
        window.sessionStorage.removeItem('storageTest'); // Try delete
        if (window.sessionStorage.getItem('storageTest')) return false; // Test delete
        return true; // Success
    } catch (e) {
        return false;
    }
}