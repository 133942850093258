import React, { useContext, useEffect } from "react";
import { FunctionComponent } from "react";
import { MozzaikAuthContext, MozzaikContext, MZK_TOKEN_KEY } from "../../utils/helpers/MozzaikAuthContext";
import { AuthentifiedMozzaikTemplate } from "./AuthentifiedMozzaikTemplate";
import { UnauthentifiedMozzaikTemplate } from "./UnauthentifiedMozzaikTemplate";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthentifiedRoutes } from "../routes/AuthentifiedRoutes";
import { UnauthentifiedRoutes } from "../routes/UnauthentifiedRoutes";
import { IClientSettings, IMozzaikToken } from "../../models/IMozzaikObjects";
import { useMsal } from "@azure/msal-react";
import * as CacheManager from "../../utils/helpers/Cache";
import intl from "react-intl-universal";
import Mozzaik365APIService from "../../services/Mozzaik365APIService";
import Mozzaik365ClientSettingsService from "../../services/Mozzaik365ClientSettingsService";
import styles from "../Home.module.scss";
import { ClientSettings } from "../../utils/helpers/ClientSettings";
import Mozzaik365GraphService from "../../services/Mozzaik365GraphService";
import { SilentRequest } from "@azure/msal-browser";
import { i18n as i18nComponentsLib } from "@mozzaik365/components/dist/index";
import { Mozzaik365ContributionCenterService } from "../../services/ContributioCenter";

interface IAuthenticationWrapperProps {}

const DEFAULT_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const AuthenticationWrapper: FunctionComponent<IAuthenticationWrapperProps> = (props) => {
  const { mozzaikContext, setMozzaikContext } = useContext(MozzaikContext);
  const { instance } = useMsal();

  async function getMozzaikToken(): Promise<string> {
    const request: SilentRequest = {
      redirectUri: window.location.origin,
      scopes: [`${process.env.REACT_APP_CONFIGURATOR_APP_ID_URL}/user_impersonation`],
    };
    const { accessToken } = await instance.acquireTokenSilent(request).catch((e) => instance.loginPopup(request));

    let mozzaikToken = "";
    let result: any = await fetch(mozzaikContext.APIs.AuthenticationAPIURL + "/api/auth", {
      method: "GET",
      headers: {
        ...DEFAULT_HEADERS,
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((val) => val.json());

    if (result) {
      mozzaikToken = result.token;
    }

    return mozzaikToken;
  }

  async function decodeAndValidateToken(mozzaikToken: string): Promise<IMozzaikToken | undefined> {
    let decoded: IMozzaikToken | undefined;
    if (!mozzaikToken || mozzaikToken === "undefined") {
      return;
    }

    let response = await fetch(mozzaikContext.APIs.AuthenticationAPIURL + "/api/auth/verify", {
      method: "POST",
      headers: DEFAULT_HEADERS,
      body: JSON.stringify({ token: mozzaikToken }),
    });

    if (response.ok) {
      decoded = await response.json();
    }

    return decoded;
  }

  function onAuthenticationSuccess(encodedToken: string, decodedToken: IMozzaikToken): MozzaikAuthContext {
    CacheManager.saveItem("localStorage", MZK_TOKEN_KEY, encodedToken);
    console.info("AuthContext: authenticated successfully");

    return {
      ...mozzaikContext,
      AuthenticationSucceeded: true,
      MozzaikDecodedToken: decodedToken,
      MozzaikToken: encodedToken,
    };
  }

  async function authenticate(): Promise<MozzaikAuthContext> {
    let context = await authenticateUsingCacheOrAPI();
    return context;
  }

  async function authenticateUsingCacheOrAPI(): Promise<MozzaikAuthContext> {
    let encodedToken = CacheManager.getItem("localStorage", MZK_TOKEN_KEY);

    if (encodedToken == null) encodedToken = await getMozzaikToken();

    if (encodedToken) {
      console.info("AuthContext : token found in API or cache, decoding and validating...");
      let decodedToken = await decodeAndValidateToken(encodedToken);
      if (decodedToken === undefined) {
        console.info("AuthContext : refreshing token");
        encodedToken = await getMozzaikToken();
        decodedToken = await decodeAndValidateToken(encodedToken);
      }

      if (decodedToken) {
        return onAuthenticationSuccess(encodedToken, decodedToken);
      } else {
        console.info("AuthContext : token is invalid");
      }
    } else {
      console.info("AuthContext : no token found in API or cache");
    }

    return mozzaikContext;
  }

  async function _loadLocales(userlanguage: string): Promise<void> {
    let locale: any = undefined;

    // remove the region part of the language
    let language: string = userlanguage.split("-")[0];

    try {
      locale = await import(`../../locales/${language}.json`);
    } catch (exception) {
      // FR by default
      locale = await import(`../../locales/fr.json`);
      language = "fr";
    }

    // set the language for the components library
    i18nComponentsLib.changeLanguage(language);

    await intl.init({
      currentLocale: language,
      locales: {
        [language]: locale,
      },
    });
  }

  async function _getUserPreferredLanguage(userId: string): Promise<string> {
    // if there is no preferred language defined in user profile, then we get the browser preferred language
    let language = navigator.language;
    const { accessToken } = await instance.acquireTokenSilent({
      scopes: ["User.Read"],
    });

    try {
      let result = await fetch("https://graph.microsoft.com/v1.0/users/" + userId, {
        method: "GET",
        headers: {
          ...DEFAULT_HEADERS,
          Authorization: `Bearer ${accessToken}`,
        },
      }).then((val) => val.json());

      if (result && result.preferredLanguage) {
        language = result.preferredLanguage;
      }
    } catch (exception) {
      console.error(exception);
    }

    return language;
  }

  useEffect(() => {
    async function authenticateAsync() {
      let clientSettings: IClientSettings | undefined;
      const contextWithoutClientsettingsAndServices = await authenticate();

      if (contextWithoutClientsettingsAndServices.AuthenticationSucceeded) {
        clientSettings = await ClientSettings.load(
          mozzaikContext.APIs.ClientSettingsAPIURL,
          contextWithoutClientsettingsAndServices.MozzaikToken,
        );
      }

      if (contextWithoutClientsettingsAndServices.MozzaikDecodedToken) {
        const language = await _getUserPreferredLanguage(
          contextWithoutClientsettingsAndServices.MozzaikDecodedToken.oid,
        );
        await _loadLocales(language);
      }
      setMozzaikContext({
        ...contextWithoutClientsettingsAndServices,
        User: {
          licenses: contextWithoutClientsettingsAndServices.MozzaikDecodedToken?.info?.licenses,
          tenantId: contextWithoutClientsettingsAndServices.MozzaikDecodedToken!.tenantid,
          tenantName: contextWithoutClientsettingsAndServices.MozzaikDecodedToken!.info!.name,
          userMail: contextWithoutClientsettingsAndServices.MozzaikDecodedToken!.email,
          userName: contextWithoutClientsettingsAndServices.MozzaikDecodedToken!.name,
          isAuthorized: clientSettings !== undefined,
        },
        MozzaikService: new Mozzaik365APIService(contextWithoutClientsettingsAndServices.MozzaikToken),
        ClientSettingsService: new Mozzaik365ClientSettingsService(
          contextWithoutClientsettingsAndServices.MozzaikToken,
        ),
        ContributionCenterService: new Mozzaik365ContributionCenterService(
          contextWithoutClientsettingsAndServices.MozzaikToken,
        ),
        graphService: new Mozzaik365GraphService(contextWithoutClientsettingsAndServices.MozzaikToken),
      });
    }
    authenticateAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      {mozzaikContext.AuthenticationSucceeded && (
        <div className={styles.home}>
          <AuthentifiedMozzaikTemplate>
            <AuthentifiedRoutes />
          </AuthentifiedMozzaikTemplate>
          <UnauthentifiedMozzaikTemplate>
            <UnauthentifiedRoutes />
          </UnauthentifiedMozzaikTemplate>
        </div>
      )}
    </Router>
  );
};
