import Config from "../utils/Config";
import { UserCustomAction } from "../models/IMozzaikObjects";
import { UEXActivationStatus } from "../models/UEXActivationStatus";
import { IMetric } from "@mozzaik365/components/dist/metrics";
import { IMetricsActiveUsers } from "./Mozzaik365API.types";

const UEXCustomAction: UserCustomAction = {
  Location: "ClientSideExtension.ApplicationCustomizer",
  Title: "MZK-Unified-Experience",
  ClientSideComponentId: "85fbf2d0-5acf-4a1c-bc02-6721f770c325",
  ClientSideComponentProperties: "{}",
};

export default class Mozzaik365APIService {
  private _mozzaikToken: string;
  private _defaultHeaders: Headers;

  constructor(mozzaikToken: string) {
    this._mozzaikToken = mozzaikToken;
    this._defaultHeaders = new Headers();
    this._defaultHeaders.append("Authorization", `JWT ${this._mozzaikToken}`);
    this._defaultHeaders.append("Accept", "application/json");
    this._defaultHeaders.append("Content-Type", "application/json");
  }

  get defaultHeaders(): Headers {
    return this._defaultHeaders;
  }

  public async setObject<T>(item: T, route: string): Promise<string> {
    let message: string = "";

    let response = await fetch(`${Config.URL.ClientSettingsAPI}/api/${route}`, {
      method: "PUT",
      headers: this._defaultHeaders,
      body: JSON.stringify(item),
    });

    if (!response.ok) {
      message = response.statusText;
    }

    return message;
  }

  public async addItem<T>(item: T, route: string): Promise<boolean> {
    let success = true;

    let response = await fetch(`${Config.URL.ClientSettingsAPI}/api/${route}`, {
      method: "POST",
      headers: this._defaultHeaders,
      body: JSON.stringify(item),
    });

    if (!response.ok) {
      success = false;
    }

    return success;
  }

  public async setItem<T>(isNew: boolean, index: number, link: T, route: string): Promise<Response> {
    let response;
    if (isNew && index === undefined) {
      response = await fetch(`${Config.URL.ClientSettingsAPI}/api/${route}`, {
        method: "POST",
        headers: this._defaultHeaders,
        body: JSON.stringify(link),
      });
    } else {
      response = await fetch(`${Config.URL.ClientSettingsAPI}/api/${route}/${index}`, {
        method: "PUT",
        headers: this._defaultHeaders,
        body: JSON.stringify(link),
      });
    }
    return response;
  }

  public async deleteItem(index: number, route: string): Promise<boolean> {
    let success = true;
    let response = await fetch(`${Config.URL.ClientSettingsAPI}/api/${route}/${index}`, {
      method: "DELETE",
      headers: this._defaultHeaders,
    });
    if (!response.ok) {
      success = false;
    }

    return success;
  }

  public async allUpdate<T>(links: T[], route: string): Promise<boolean> {
    let success = true;
    let response = await fetch(`${Config.URL.ClientSettingsAPI}/api/${route}`, {
      method: "PUT",
      headers: this._defaultHeaders,
      body: JSON.stringify(links),
    });
    if (!response.ok) {
      success = false;
    }

    return success;
  }

  public async cudMulti<T>(link: T, method: string, route: string) {
    let response = await fetch(`${Config.URL.ClientSettingsAPI}/api/${route}`, {
      method: method,
      headers: this._defaultHeaders,
      body: JSON.stringify(link),
    });
    if (response.ok) {
      return await response.json();
    }
  }

  public async getMulti(route: string) {
    let response = await fetch(`${Config.URL.ClientSettingsAPI}/api/${route}`, {
      method: "GET",
      headers: this._defaultHeaders,
    });
    if (response.ok) {
      return await response.json();
    }
  }

  public async authSiteEngine(configuratorToken: string) {
    if (configuratorToken) {
      let response = await fetch(`${Config.URL.AuthenticationAPI}/api/auth/siteengine`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${configuratorToken}`,
        },
      });
      if (response.ok) {
        return await response.json();
      }
    }
  }

  public async authAdmins(configuratorToken: string) {
    if (configuratorToken) {
      let response = await fetch(`${Config.URL.AuthenticationAPI}/api/auth/admins`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${configuratorToken}`,
        },
      });
      if (response.ok) {
        return await response.json();
      }
    }
  }

  public async getEngine(route: string) {
    let response = await fetch(`${Config.URL.EngineAPI}/api/${route}`, {
      method: "GET",
      headers: this._defaultHeaders,
    });
    if (response.ok) {
      return await response.json();
    }
  }

  public async getSharepointToken(sharepointUrl: string, token: string, appclientid: string) {
    const response = await fetch(`${Config.URL.AuthenticationAPI}/api/auth/sharepoint/${sharepointUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ appclientid: appclientid }),
    });

    if (response.ok) {
      const result = await response.json();

      return result.sptoken;
    }

    return "";
  }

  public async getFormDigestValue(appCatalogUrl: string, token: string) {
    let value: string = "";

    const response = await fetch(`${appCatalogUrl}/_api/contextinfo`, {
      method: "POST",
      headers: {
        Accept: "application/json;odata=nometadata",
        "Content-Type": "application/json;odata=nometadata",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      const info = await response.json();
      value = info.FormDigestValue;
    }

    return value;
  }

  public async getAvailableApps(appCatalogUrl: string, token: string) {
    let response = await fetch(`${appCatalogUrl}/_api/web/tenantappcatalog/AvailableApps`, {
      method: "GET",
      headers: {
        Accept: "application/json;odata=nometadata",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      let info = await response.json();
      return info.FormDigestValue;
    }
  }

  public async getTenantAppCatalogUrl(sharepointUrl: string, token: string) {
    let url: string = "";

    let response = await fetch(`https://${sharepointUrl}/_api/SP_TenantSettings_Current`, {
      method: "GET",
      headers: {
        Accept: "application/json;odata=nometadata",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      let info = await response.json();
      url = info.CorporateCatalogUrl;
    }

    return url;
  }

  public async sendSites<T>(objectTosend: T, route: string): Promise<boolean> {
    let success = true;
    let response = await fetch(`${Config.URL.EngineAPI}/api/${route}`, {
      method: "POST",
      headers: this._defaultHeaders,
      body: JSON.stringify(objectTosend),
    });
    if (!response.ok) {
      success = false;
    }

    return success;
  }

  public async getUserCustomActions(siteUrl: string, token: string): Promise<UEXActivationStatus> {
    let spResponse: UEXActivationStatus = {
      isActive: false,
      uexCustomActionId: "",
      error: "",
    };

    const url = `${siteUrl}/_api/web/usercustomactions`;

    let response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      const result = await response.json();

      if (result && result.value) {
        const userCustomActions: UserCustomAction[] = result.value;
        const uexCustomAction = userCustomActions.find(
          (action) => action.ClientSideComponentId === UEXCustomAction.ClientSideComponentId,
        );
        if (uexCustomAction) {
          spResponse.isActive = true;
          spResponse.uexCustomActionId = uexCustomAction.Id!;
        }
      }
    } else {
      spResponse.error = response.statusText;
    }

    return spResponse;
  }

  public async addUEXUserCustomActions(endpoint: string, token: string): Promise<string> {
    let message: string = "";

    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(UEXCustomAction),
    });

    if (!response.ok) {
      message = response.statusText.length ? response.statusText : `Error ${response.status}`;
    }

    return message;
  }

  public async deleteUEXUserCustomActions(
    customactionsendpoint: string,
    token: string,
    customactionid: string,
  ): Promise<string> {
    let message: string = "";

    let response = await fetch(`${customactionsendpoint}('${customactionid}')`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-http-method": "DELETE",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      message = response.statusText;
    }

    return message;
  }

  public async addAppToAppCatalog(
    url: string,
    solutionFileName: string,
    accessToken: string,
    blob: Blob,
    isTenantWide: boolean = true,
  ) {
    let error = "";
    const scope = isTenantWide ? "tenantappcatalog" : "sitecollectionappcatalog";

    const formDigestValue = await this.getFormDigestValue(url, accessToken);

    const addapp = await fetch(`${url}/_api/web/${scope}/Add(overwrite=true, url='${solutionFileName}')`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json;odata=nometadata",
        "Content-Type": "application/json",
        "X-RequestDigest": formDigestValue,
        binaryStringRequestBody: "true",
      },
      body: blob,
    });

    if (addapp.ok) {
      const solution: { UniqueId: string } = await addapp.json();

      let deployapp = await fetch(`${url}/_api/web/${scope}/AvailableApps/GetById('${solution.UniqueId}')/deploy`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json;odata=nometadata",
          "content-type": "application/json;odata=nometadata;charset=utf-8",
          "X-RequestDigest": formDigestValue,
        },
        body: JSON.stringify({ skipFeatureDeployment: true }),
      });

      if (!deployapp.ok) {
        error = deployapp.statusText;
      }
    } else {
      error = addapp.statusText;
    }

    // Empty if success
    return error;
  }

  public async removeAppFromAppCatalog(
    url: string,
    solutionID: string,
    accessToken: string,
    isTenantWide: boolean = true,
  ) {
    let error = "";
    const scope = isTenantWide ? "tenantappcatalog" : "sitecollectionappcatalog";

    const formDigestValue = await this.getFormDigestValue(url, accessToken);

    const getapp = await fetch(`${url}/_api/web/${scope}/AvailableApps/GetById('${solutionID}')`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json;odata=nometadata",
      },
    });

    if (getapp.ok) {
      const retractapp = await fetch(`${url}/_api/web/${scope}/AvailableApps/GetById('${solutionID}')/Retract`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json;odata=nometadata",
          "X-RequestDigest": formDigestValue,
        },
      });

      let removeapp = await fetch(`${url}/_api/web/${scope}/AvailableApps/GetById('${solutionID}')/Remove`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/json;odata=nometadata",
          "X-RequestDigest": formDigestValue,
        },
      });

      // In case solution is retracted but not removed, no error is thrown
      if (!removeapp.ok || !retractapp.ok) {
        error = removeapp.statusText || retractapp.statusText;
      }
    } else {
      // If not ok, it means the app was not found. Therefore, it should be removed
      console.warn(`Solution ${solutionID} not found on ${url}. It will be removed`);
    }

    // Empty if success or solution not found
    return error;
  }

  public async enableOrDisableUEX(siteUrl: string, accessToken: string, enable: boolean): Promise<string> {
    const usercustomactionendpoint = `${siteUrl}/_api/web/usercustomactions`;
    let error = "";

    if (enable) {
      const message = await this.addUEXUserCustomActions(usercustomactionendpoint, accessToken);

      if (message) {
        error = message;
      }
    } else {
      const siteUex = await this.getUserCustomActions(siteUrl, accessToken);

      if (!siteUex.error) {
        if (siteUex.isActive) {
          const message = await this.deleteUEXUserCustomActions(
            usercustomactionendpoint,
            accessToken,
            siteUex.uexCustomActionId,
          );

          if (message) {
            error = message;
          }
        }
      } else {
        error = siteUex.error;
      }
    }

    return error;
  }

  // metrics function

  private async getMetrics<T>(route: string, startDate?: string, endDate?: string): Promise<T | undefined> {
    const url = new URL(route);

    if (startDate) {
      url.searchParams.append("startDate", startDate);
    }
    if (endDate) {
      url.searchParams.append("endDate", endDate);
    }

    let response = await fetch(url.href, {
      method: "GET",
      headers: this._defaultHeaders,
    });

    if (response.ok) {
      const message = await response.json();
      return message.data;
    }
  }

  public async getTotalDistinctActiveUsers(startDate?: string, endDate?: string): Promise<number> {
    let requestUrl = `${Config.URL.ClientSettingsAPI}/api/metrics/users/total`;
    const req = await this.getMetrics<number>(requestUrl, startDate, endDate);
    return req || 0;
  }

  public async getActiveUserCategories(): Promise<IMetricsActiveUsers> {
    let requestUrl = `${Config.URL.ClientSettingsAPI}/api/metrics/users/categories`;
    const req = await this.getMetrics<IMetricsActiveUsers>(requestUrl);

    return (
      req || {
        activeUsers: 0,
        regularUsers: 0,
        occasionalUsers: 0,
      }
    );
  }

  public async getAverageActiveUsers(startDate?: string, endDate?: string): Promise<number> {
    let requestUrl = `${Config.URL.ClientSettingsAPI}/api/metrics/users/average`;
    const req = await this.getMetrics<number>(requestUrl, startDate, endDate);
    return req || 0;
  }

  public async getDailyUsers(startDate?: string, endDate?: string): Promise<IMetric[]> {
    let requestUrl = `${Config.URL.ClientSettingsAPI}/api/metrics/users/daily`;
    const req = await this.getMetrics<IMetric[]>(requestUrl, startDate, endDate);
    return req || [];
  }

  public async getMostVisitedPages(startDate?: string, endDate?: string): Promise<IMetric[]> {
    let requestUrl = `${Config.URL.ClientSettingsAPI}/api/metrics/pages/allPages`;
    const req = await this.getMetrics<IMetric[]>(requestUrl, startDate, endDate);
    return req || [];
  }

  public async getMostVisitedArticles(startDate?: string, endDate?: string): Promise<IMetric[]> {
    let requestUrl = `${Config.URL.ClientSettingsAPI}/api/metrics/pages/articles`;
    const req = await this.getMetrics<IMetric[]>(requestUrl, startDate, endDate);
    return req || [];
  }

  public async getMostVisitedSites(startDate?: string, endDate?: string): Promise<IMetric[]> {
    let requestUrl = `${Config.URL.ClientSettingsAPI}/api/metrics/sites/mostvisited`;
    const req = await this.getMetrics<IMetric[]>(requestUrl, startDate, endDate);
    return req || [];
  }
}
