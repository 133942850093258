import Config from "../utils/Config";
import Mozzaik365APIService from "./Mozzaik365APIService";
import { IApplication, IClientSettings, IFooterLink, IGlobalNavigation, IInstallation } from '../models/IMozzaikObjects';

export default class Mozzaik365ClientSettingsService extends Mozzaik365APIService {
    public async getAllCategories(): Promise<string[]> {
        let categories:  string[] = [];

        let response = await fetch(`${Config.URL.ClientSettingsAPI}/api/categories`, {
            method: 'GET',
            headers: this.defaultHeaders
        });

        if (response.ok) {
            categories = await response.json();
        }

        return categories;
    }

    public async addCategories(newcategories: string[]): Promise<string[]> {
        let categories:  string[] = [];

        let response = await fetch(`${Config.URL.ClientSettingsAPI}/api/categories`, {
            method: 'POST',
            headers: this.defaultHeaders,
            body: JSON.stringify(newcategories)
        });

        if (response.ok) {
            categories = await response.json();
        }

        return categories;
    }

    public async addApplications(apps: IApplication[]): Promise<boolean> {
        return this.addItem<IApplication[]>(apps, 'apps');        
    }

    public async deleteApplications(applications: IApplication[]): Promise<IApplication[]> {
        let remaining: IApplication[] = [];

        let response = await fetch(`${Config.URL.ClientSettingsAPI}/api/apps`, {
            method: 'DELETE',
            headers: this.defaultHeaders,
            body: JSON.stringify(applications)
        });

        if (response.ok) {
            const tenantinfo: IClientSettings = await response.json();
            remaining = tenantinfo.Applications;
        }

        return remaining;
    }

    public async deleteFooterLinks(footer: IFooterLink[]): Promise<IFooterLink[]> {
        let remaining: IFooterLink[] = [];

        let response = await fetch(`${Config.URL.ClientSettingsAPI}/api/uex/footer`, {
            method: 'DELETE',
            headers: this.defaultHeaders,
            body: JSON.stringify(footer)
        });

        if (response.ok) {
            const tenantinfo: IClientSettings = await response.json();
            remaining = tenantinfo.UEX.footer.links;
        }

        return remaining;
    }

    public async deleteNavigationLinks(navigation: IGlobalNavigation[]): Promise<IGlobalNavigation[]> {
        let remaining: IGlobalNavigation[] = [];

        let response = await fetch(`${Config.URL.ClientSettingsAPI}/api/uex/navigations`, {
            method: 'DELETE',
            headers: this.defaultHeaders,
            body: JSON.stringify(navigation)
        });

        if (response.ok) {
            const tenantinfo: IClientSettings = await response.json();
            remaining = tenantinfo.UEX.globalNavigation;
        }

        return remaining;
    }
    
    public async addOrUpdateInstalledSolution(isTenantWide: boolean, appcatalogs: string[], solutionName: string): Promise<IInstallation | undefined> {
        let installation = undefined;

        let response = await fetch(`${Config.URL.ClientSettingsAPI}/api/installation/solutions`, {
            method: 'POST',
            headers: this.defaultHeaders,
            body: JSON.stringify({
                isTenantWide: isTenantWide,
                installedAppCatalogs: appcatalogs,
                solutionName: solutionName
            })
        });

        if (response.ok) {
            const tenant: IClientSettings = await response.json();

            installation = tenant.Installation;
        }

        return installation;
    }

    public async updateInstallationInfo(installation: IInstallation): Promise<boolean> {
        let success: boolean = false;

        let response = await fetch(`${Config.URL.ClientSettingsAPI}/api/installation`, {
            method: 'POST',
            headers: this.defaultHeaders,
            body: JSON.stringify(installation)
        });

        if (response.ok) {
            success = true;
        }

        return success;
    }


    public async isConnectionActive(): Promise<boolean> {
        let isActive: boolean = false;

        const response = await fetch(`${Config.URL.ClientSettingsAPI}/api/connection`, {
            method: 'GET',
            headers: this.defaultHeaders            
        });

        if (response.ok) {
            const connection = await response.json();

            isActive = connection.isActive;
        }

        return isActive;
    }
}